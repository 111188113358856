import React from 'react'
import styled from 'styled-components'
import { MdArrowForward, MdLink } from "react-icons/md";

const ToggleButton = styled.button`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  padding: 8px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0 0 12px 0;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }

  &.isActive {
    background-color: ${(props) => props.theme.theme.colors.transparentGreenHover};
    border: 1px solid ${(props) => props.theme.theme.colors.green};
  }
`

export default (props) => {

  return (
    <ToggleButton onClick={props.onClick} className={props.isActive ? "isActive" : "" }>
      {props.text}
      {props.icon}
    </ToggleButton>

  )
}
