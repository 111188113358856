import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar
} from 'recharts';
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import compoundInterestTable from '../../assets/compound_interest_table.png'
import compoundInterestGraph from '../../assets/compound_interest_graph.png'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import ToggleButton from '../../components/ToggleButton'

const H2 = styled.h2`
  font-weight: 300;
`

const Image = styled.img`
  width: 100%;
`

const CalculatorGrid = styled(ContainerMain)`
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 24px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }

  .MuiRadio-colorSecondary.Mui-checked{
    color: ${props => props.theme.theme.colors.green};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  /* color: ${(props) => props.theme.theme.colors.green}; */
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Year: </strong>
          {payload[0].payload.year}</CardText400>
        <CardText400>
          <strong>Future Value: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.localFutureValue.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

const CustomTooltipHSA= (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Year: </strong>
          {payload[0].payload.year}
        </CardText400>
        <CardText400>
          <strong>Cumulative Net Deposits: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.totalDeposits.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Cumulative Asset Growth: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.totalAssetGrowth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Total HSA Value: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.totalValue.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const HSAGrowthCalc = () => {

  const [ currentAge, setCurrentAge ] = useState(30)
  const [ retirementAge, setRetirementAge ] = useState(67)
  const [ investmentRate, setInvestmentRate ] = useState(7)
  const [ inflationRate, setInflationRate ] = useState(3)
  const [ timeLength, setTimeLength ] = useState(5)
  const [ principal, setPrincipal ] = useState(1000)
  const [ payment, setPayment ] = useState(4150)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(1)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(1)
  const [ HSAType, setHSAType ] = useState('individual')
  const [ annualHSAExpense, setAnnualHSAExpense ] = useState('500')

  // Update these each year

  const individualAnnualMax = 4150
  const familyAnnualMax = 8300

  const handleError = () => {
    let error = false
    if(HSAType == "individual"){
      payment > individualAnnualMax ? error = true : error = false
    } else {
      payment > familyAnnualMax ? error = true : error = false
    }
    console.log(error)
    return error
  }

  const errorText = () => {
    let text=""
    if(HSAType == "individual"){
      text = handleError() ? `Please reduce your annual contribution to be within the individual limit of $${individualAnnualMax.toLocaleString('en-US')}`  : ""
    } else {
      text = handleError() ? `Please reduce your annual contribution to be within the family limit of $${familyAnnualMax.toLocaleString('en-US')}.`  : ""  
    }
    return text
  }

  console.log(HSAType)

  const rate = (Math.pow( 1 + ((investmentRate/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const futureValue = principal * Math.pow(1+rate, nper) + (payment - annualHSAExpense) * ( (Math.pow(1+rate, nper)-1)/ rate )

  const calcCompoundingData = () => {
    let compoundingData = []
    for(let i = 0; i <= timeLength; i++){
      console.log('test')
      const localNper = annualPaymentPeriods * i
      const localFutureValue = principal * Math.pow(1+rate, localNper) + (payment - annualHSAExpense) * ( (Math.pow(1+rate, localNper)-1)/ rate )
      const totalDeposits = (payment - annualHSAExpense) * i + principal

      compoundingData.push({
        year: i,
        totalAssetGrowth: localFutureValue - totalDeposits,
        totalDeposits: totalDeposits,
        totalValue: localFutureValue
      })
    }

    console.log(compoundingData)
    return compoundingData
  }

  const compoundingData = calcCompoundingData()

  const updateHSAType = (e, planType) => {
    e.preventDefault();
    setHSAType(planType)
  }


  return (
    <Layout>
      <SEO
        title={'HSA Growth Calculator'}
        description={'Project how much your HSA could grow over time'}
      />
      <ReactTooltip />

      <CalculatorGrid>
        <form>

        <AllocationGroupGray>
          <Label>
            Health Plan Type
          </Label>

          <ToggleButton 
            text="Individual"
            isActive= {HSAType=="individual"}
            onClick={(e) => updateHSAType(e, "individual")}
          />

          <ToggleButton 
            text="Family"
            isActive= {HSAType=="family"}
            onClick={(e) => updateHSAType(e, "family")}
          />
            {/* <RadioGroup 
                aria-label="HSA Types" 
                name="HSAtypes" 
                value={HSAType} 
                onChange={event => setHSAType(event.target.value)}
                >
                <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                <FormControlLabel value="family" control={<Radio />} label="Family" />
            </RadioGroup> */}
          </AllocationGroupGray>
            
          <ThemedTextarea
            required
            id="outlined-required"
            label="HSA Starting Value"
            value={principal}
            onChange={event => setPrincipal(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />

          <ThemedTextarea
            required
            id="outlined-required"
            label="Length of time in years"
            value={timeLength}
            onChange={event => setTimeLength(event.target.value)}
            variant="outlined"
            fullWidth
          />

          <ThemedTextarea
            id="outlined-required"
            label="Annual HSA contribution"
            value={payment}
            onChange={event => setPayment(event.target.value)}
            variant="outlined"
            error = {handleError()}
            helperText={errorText()}
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />

          <ThemedTextarea
            id="outlined-required"
            label="Annual HSA expenses"
            value={annualHSAExpense}
            onChange={event => setAnnualHSAExpense(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />

        <Label>
          Rate of return
          {/* <MdHelp size="1.4rem"
              data-tip={`This is your etimated annual interest rate of return for your investment.`}
          /> */}
        </Label>
        <SliderGrid>

          <ThemedSlider
            value={investmentRate}
            aria-labelledby="discrete-slider-always"
            step={0.25}
            min={0}
            max={20}
            marks={[
              {
                value: 0,
                label: '0%'
              },
              {
                value: 7,
                label: '7%'
              },
              {
                value: 20,
                label: '20%'
              }
            ]}
            valueLabelDisplay="auto"
            onChange={ (event, value) => setInvestmentRate(value)}
          />

          <ThemedTextarea
            id="outlined-name"
            label="Rate"
            value={investmentRate}
            onChange={event => setInvestmentRate(event.target.value)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            fullWidth
          />
        </SliderGrid>

        <AllocationGroupGreen>
          <Label>
            Projected HSA value
            {/* <MdHelp
              size="1.2rem"
              data-tip={`This is your annual savings on fuel by driving the more fuel efficient option.`}
            /> */}
          </Label>
          <span className="result-value">
            <NumberFormat
              displayType={"text"}
              value={(futureValue).toFixed(0)}
              thousandSeparator
              prefix="$"
            />
          </span>
        </AllocationGroupGreen>

        </form>

          <GraphDesktop>
            <ResponsiveContainer width='100%' height={500} >
              <BarChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="year" label={{value: "Time (years)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                />
                <Tooltip
                  content={<CustomTooltipHSA/>}
                />
                <Legend verticalAlign="top" height={36} />
                <Bar type='monotone' name="Cumulative net deposits" dataKey='totalDeposits' stackId="a" fill='#2196F3' />
                <Bar type='monotone' name="Cumulative asset growth" dataKey='totalAssetGrowth' stackId="a" fill='#3AC732' />
              </BarChart>
            </ResponsiveContainer>
          </GraphDesktop>

          <GraphMobile>
            <ResponsiveContainer width='100%' height={500} >
              <BarChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="year" label={{value: "Time (years)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  mirror
                />
                <Tooltip
                  content={<CustomTooltipHSA/>}
                />
                <Legend verticalAlign="top" height={36} />
                <Bar type='monotone' name="Cumulative net deposits" dataKey='totalDeposits' stackId="a" fill='#2196F3' />
                <Bar type='monotone' name="Cumulative asset growth" dataKey='totalAssetGrowth' stackId="a" fill='#3AC732' />
              </BarChart>
            </ResponsiveContainer>
          </GraphMobile>

      </CalculatorGrid>

    </Layout>
  )
}

export default HSAGrowthCalc

